/* You can add global styles to this file, and also import other style files */
:root{
  --background-color: #F5F5F5;
  --default-color: #000;
  --primary-color: #8d8d8d;
  --secondary-color: #FFAAFF ;
  --disabled-color: #ABABAB;
}

html, body { height: 100%; }
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: var(--background-color)! important;
  overflow: auto;
}

a{
  color: var(--primary-color) !important;
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
/* Other imports */
@import '~@fortawesome/fontawesome-free/css/all.min.css';


.title-header{
  text-align: left;
  color: var(--primary-color);

  h1, h2, h3, h4, h5, h6{
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.3em;
  }
}

.modal-content{
  width: fit-content;
  height: fit-content;
  margin: auto;
}
.modal-dialog {
  max-width: 95vw !important;
  margin: auto !important;
}

@media (min-width: 576px)
{
  .modal-dialog {
    max-width: 95vw !important;
    margin: auto !important;
  }
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: var(--primary-color) !important;
}


ngb-datepicker,
.ngb-dp-months,
.ngb-dp-header,
.ngb-dp-month,
.ngb-dp-weekdays,
.ngb-dp-week{
  width: 100%!important;
  max-width: 700px;
}
.ngb-dp-weekday{
  width: calc(100% / 7)!important;
}
.ngb-dp-day{
  width: calc(100% / 7)!important;
  height: auto!important;
  aspect-ratio: 1 / 1;
  &>span{
    width: calc(100% - 5px)!important;
    height: calc(100% - 5px);
    display: flex!important;
    justify-content: center;
    align-items: center;
    border-radius: 50%!important;
  }
}
.custom-day.hidden{
  display: none!important;
}
.ngb-dp-weekdays {
  margin-bottom: 10px;
}
.ngb-dp-day.after-month{
  display: none!important;
  width: 0!important;
  height: 0!important;
}

.ngb-dp-weekdays{
  border-bottom: none!important;
}


ngb-datepicker{
  border: none!important;
}
.ngb-dp-weekdays{
  border-bottom: none!important;
}

.ngb-dp-navigation-chevron,
.ngb-dp-weekday{
  color: var(--primary-color) !important
}

